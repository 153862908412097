// Home.js
import React, { useRef, useEffect } from 'react';
import './Home.css';
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Parallax } from 'react-parallax';

const Home = () => {
  const currentYear = new Date().getFullYear();

  const heroSectionStyle = {
    backgroundImage: `url(/13.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  };

  // Crear una referencia para la imagen de About Us
  const aboutImageRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null, // Observa en relación al viewport
      rootMargin: '0px',
      threshold: 0.1, // 10% visible
    };

    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
          observer.unobserve(entry.target); // Dejar de observar después de animar
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (aboutImageRef.current) {
      observer.observe(aboutImageRef.current);
    }

    // Limpiar el observer al desmontar el componente
    return () => {
      if (aboutImageRef.current) {
        observer.unobserve(aboutImageRef.current);
      }
    };
  }, []);

  return (
    <div className="home-container">
      {/* Navigation Bar */}
      <header className="navbar">
        <div
          className="logo-container"
          onClick={() => scroll.scrollToTop()}
          style={{ cursor: 'pointer' }}
        >
          <img src="/logothl.png" alt="Totem Health Lab" className="logo-image" />
        </div>
        <nav className="nav-menu">
          <Link to="home" smooth={true} duration={500}>
            Home
          </Link>
          <Link to="about" smooth={true} duration={500}>
            About Us
          </Link>
          <Link to="research" smooth={true} duration={500}>
            Research
          </Link>
          <Link to="innovation" smooth={true} duration={500}>
            Innovation
          </Link>
          <Link to="contact" smooth={true} duration={500}>
            Contact
          </Link>
        </nav>
      </header>

      {/* Hero Section */}
      <section id="home" className="hero-section" style={heroSectionStyle}>
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Innovation in Bioinformatics</h1>
          <p>Shaping the future of health through advanced technology</p>
          <a href="https://calendly.com/joan-totemhealthlab/demo-totem-health-lab" target="_blank" rel="noopener noreferrer">
            <button className="cta-button">Request a demo</button>
          </a>
        </div>
      </section>

      {/* About Us Section */}
      <section id="about" className="about-section">
        <div className="about-content">
          <div className="about-text">
            <h2>About Us</h2>
            <p>
              At Totem Health Lab, we transform bioinformatics by integrating groundbreaking research and cutting-edge technology. We harness data and innovation to address complex challenges, drive healthcare advancements, and create solutions that empower professionals and improve patient outcomes.
            </p>
          </div>
          <div className="about-image" ref={aboutImageRef}>
            <img src="/19.png" alt="Research Team" />
          </div>
        </div>
      </section>

      {/* Research Section */}
      <section id="research" className="research-section">
        <h2>Our Research</h2>
        <div className="research-cards">
          <div className="card">
            <img src="/17.png" alt="Computational Genomics" />
            <h3>Lung Cancer</h3>
            <p>Using quantum circuits to accelerate MRI image analysis for lung cancer.</p>
          </div>
          <div className="card">
            <img src="/16.png" alt="Synthetic Biology" />
            <h3>ALL</h3>
            <p>Developing deep neural networks to segment and classify blood cells accurately.</p>
          </div>
          <div className="card">
            <img src="/18.png" alt="Artificial Intelligence in Medicine" />
            <h3>Skin Cancer</h3>
            <p>Utilizing convolutional networks to quickly identify potentially cancerous moles.</p>
          </div>
        </div>
      </section>

      {/* Technological Innovation Section */}
      <section id="innovation" className="innovation-section">
        <h2>Technological Innovation</h2>
        <div className="innovation-content">
          <Parallax
            bgImage="/9.png"
            strength={300}
            className="parallax-section"
            bgImageAlt="Artificial Intelligence Background"
          >
            <div className="innovation-overlay">
              <div className="innovation-text">
                <h3>Artificial Intelligence</h3>
                <p>
                  We develop AI systems that enhance various aspects of healthcare through advanced data processing.
                </p>
                <p>
                  Our deep learning technology is designed to adapt and evolve with each new research, ensuring innovative and effective solutions in the health sector.
                </p>
              </div>
            </div>
          </Parallax>
          <Parallax
            bgImage="/11.png"
            strength={300}
            className="parallax-section"
            bgImageAlt="Quantum Computing Background"
          >
            <div className="innovation-overlay">
              <div className="innovation-text">
                <h3>Quantum Computing</h3>
                <p>
                  We leverage quantum computing to develop models that enhance computational capabilities in various applications.
                </p>
                <p>
                  Through quantum simulations, we process information at unparalleled speeds, significantly improving computational accuracy and efficiency.
                </p>
              </div>
            </div>
          </Parallax>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="contact-section">
        <div className="contact-content">
          <h2>Contact</h2>
          <p>If you would like more information or to collaborate with us, please reach out at:</p>
          <div className="contact-details">
            <div className="contact-item">
              <p><strong>Totem Health Lab</strong></p>
              <p>Mob: +44 7707 189314</p>
              <p>Email: <a href="mailto:info@totemhealthlab.org">info@totemhealthlab.org</a></p>
              <p>6 Mid Park, East Kilbride, Glasgow, G75 OBZ</p>
              <img src="/logothl.png" alt="Totem Health Lab Logo" className="contact-logo" />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <p>&copy; {currentYear} Totem Health Lab. All rights reserved.</p>
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/totemhealthlab" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <FaLinkedin />
          </a>
          <a href="https://twitter.com/totemhealthlab" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter />
          </a>
          <a href="https://www.facebook.com/totemhealthlab" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/totemhealthlab" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Home;